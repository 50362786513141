@import "./src/assets/scss/main.scss";




















































































































































































































































































.cabinet {
    display: flex;
    justify-content: space-between;
    margin-top: 85px;
    padding-bottom: 270px;

    @include media-breakpoint-down(md) {
        padding-bottom: 110px;
        margin-top: 25px;
        flex-direction: column;
    }

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        background: #ffffff;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding: 40px 10px 0px 49px;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            padding-top: 20px;
        }
    }

    &__header {
        margin-bottom: 29px;
        font-size: 24px;
    }
}

#app>div {
    background: url();
    background: #f5f5f5;
}

.footer {
    background: #fff;
}

.top-navbar {
    background: #fff !important;
}

.inputs-line {
    @include media-breakpoint-down(md) {
        display: flex;
        overflow: scroll;
    }

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;

        @include media-breakpoint-down(md) {
            white-space: nowrap;
        }
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 10px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 109.5%;
        /* or 15px */

        color: #626262;

        border: 2px solid #f5f5f5;
        margin-bottom: 10px;
        border-radius: 12px;
        margin-right: 10px;
        text-align: center;
        // width: 160px;
        padding-left: 33px;
        padding-right: 33px;
        cursor: pointer;
        background: #f5f5f5;

        @include media-breakpoint-down(md) {
            white-space: nowrap;
        }
    }

    // label:hover {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    // input[type="checkbox"]:focus+label {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    input[type="checkbox"]:checked+label,
    input[type="radio"]:checked+label {
        background-color: #3f2a2c;
        border-color: #3f2a2c;
        color: #fff;
    }

    &--orange {

        input[type="checkbox"]:checked+label,
        input[type="radio"]:checked+label {
            background-color: $orange;
            border-color: $orange;
            color: #fff;
        }
    }
}

.autoOrder-inputs {
    display: flex;
    flex-wrap: wrap;

    &__day {
        width: 156px;
        height: 48px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        border: 1px solid #d3d3d3;
        background: url(../assets/calendar-orange.svg) 88% 50% no-repeat;
        border-radius: 9px;
        padding-left: 22px;

        &--orange {
            background: url(../assets/calendar-white.svg) 88% 50% no-repeat $orange;
            color: #fff;
        }
    }

    &__add-day {
        background: #fff;
        border: 1px dashed #d3d3d3;
        box-sizing: border-box;
        border-radius: 9px;
        width: 210px;
        height: 48px;
        text-align: left;
        padding-left: 22px;
        background: url(../assets/calendar-gray.svg) 88% 50% no-repeat;
    }
}

.autoOrderItem__header {
    font-size: 18px;
    line-height: 109.5%;
    margin-bottom: 15px;

    @include media-breakpoint-down(md) {
        text-align: center;
    }
}

.autoOrder-inputs-days {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            margin-bottom: 15px;
        }
    }
}

.calendar {
    position: relative;
    background: url(../assets/calendar-orange.svg) 85% 50% no-repeat !important;
    width: fit-content;
    // margin-left: auto;
    // margin-right: auto;
    margin-right: 10px;
    margin-bottom: 10px;
}

.btnSelectDay {
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 9px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    height: 48px;
    padding-right: 60px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding-left: 22px;
    color: #000000;
}

.btnSelectDay.active {
    background: #ccc;
}

.vd-activator {}

.vd-wrapper {
    display: block;
    margin-left: inherit;
    margin-right: inherit;

    div {
        display: block !important;
    }
}
